import axios from '@/libs/api.request'

export const getInvoiceReceiptAPI = () => {
    return axios.request({
        url: 'invoice-receipt',
        method: 'get'
    })
}

export const getInvoiceReceiptByIdAPI = (id) => {
    return axios.request({
        url: 'invoice-receipt/' + id,
        method: 'get'
    })
}

export const getInContByIdAPI = (id) => {
    return axios.request({
        url: 'invoice-receipt-contract/' + id,
        method: 'get'
    })
}

export const getInPpsByIdAPI = (id) => {
    return axios.request({
        url: 'invoice-receipt-proposal/' + id,
        method: 'get'
    })
}

export const createInvoiceReceiptAPI = (credentials) => {
    return axios.request({
        url: 'invoice-receipt',
        data: credentials,
        method: 'post'
    })
}

export const confirmPlayInvoiceAPI = (credentials) => {
    return axios.request({
        url: 'invoice-receipt-confirm',
        data: credentials,
        method: 'post'
    })
}

export const updateReceiptAPI = (credentials) => {
    return axios.request({
        url: 'invoice-receipt/' + credentials.id,
        data: credentials,
        method: 'put'
    })
}

export const deleteInvoiceReceiptAPI = (id) => {
    return axios.request({
        url: 'invoice-receipt/' + id,
        method: 'delete'
    })
}