import axios from '@/libs/api.request'


export const getReportInvoiceByIdAPI = (id) => {
    return axios.request({
        url: 'report-invoice/' + id,
        method: 'get'
    })
}

export const getReportInvoiceApplByIdAPI = (id) => {
    return axios.request({
        url: 'report-invoice-appliance/' + id,
        data: credentials,
        method: 'get'
    })
}