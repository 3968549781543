<template>
  <div v-if="userGroup == 'administrator' || userGroup == 'manage'">
    <CCard>
      <CCardHeader>
        <strong>ຂໍ້ມູນໃບແຈ້ງບໍລິການ ແລະ ໃບຮັບເງິນ</strong>
        <CButton @click="modalCreate = true" variant="outline" color="primary" class="float-right">
          <CIcon name="cil-plus" />ສ້າງໃບຮຽກເກັບ
        </CButton>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="12">
            <CDataTable
              :items="items"
              :fields="fields"
              :loading="loading"
              hover
              sorter
              :table-filter="{placeholder: 'ຄົ້ນຫາ...', label: ' ', align: 'right'}"
              :items-per-page="20"
              pagination
            >
              <template #sum_total="row">
                <td class="text-right">{{row.item.sum_total | numFormat(',000.00')}}</td>
              </template>
              <template #inv_status="{item}">
                <td>
                  <CBadge :color="getBadge(item.inv_status)">
                    <strong v-if="item.inv_status==0">ລໍາຖ້າຈ່າຍເງິນ</strong>
                    <strong v-if="item.inv_status==1">ຈ່າຍເງິນແລ້ວ</strong>
                  </CBadge>
                </td>
              </template>
              <template #actions="row">
                <td>
                  <CDropdown
                    size="sm"
                    toggler-text="ຈັດການ"
                    color="dark"
                    class="m-0 d-inline-block"
                  >
                    <CDropdownItem
                      v-on:click="onClickViewInvoice(row.item)"
                    >ໃບຮຽກເກັບ</CDropdownItem>
                    <CDropdownItem
                      v-on:click="onClickViewReceipt(row.item)"
                    >ໃບຮັບເງິນ</CDropdownItem>
                    <CDropdownItem
                      :disabled="row.item.inv_status == 1 || userGroup != 'administrator'"
                      v-on:click="onClickConfirmReceipt(row.item)"
                    >ຊໍາລະເງິນ</CDropdownItem>
                    <CDropdownItem
                      v-on:click="onClickUpdateReceipt(row.item)"
                    >ແກ້ໄຂ</CDropdownItem>
                    <CDropdownItem
                      :disabled="row.item.inv_status == 1 || userGroup != 'administrator'"
                      v-on:click="onClickDeleteReceipt(row.item)"
                    >ລົບ</CDropdownItem>
                  </CDropdown>
                </td>
              </template>
            </CDataTable>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <CModal
      title="ຟອມສ້າງໃບແຈ້ງບໍລິການ"
      size="lg"
      :show.sync="modalCreate"
      :closeOnBackdrop="false"
    >
      <inReFormCreate
        :cont="contStList"
        :pps="ppsStList"
        @on_success="onSubmitCreate"
        @on_cancle="onSubmitCancle"
      />
      <template #footer>
        <div></div>
      </template>
    </CModal>
    <CModal
      title="ລາຍລະອຽດຂໍ້ມູນທີ່ຈະທໍາການຊໍາລະເງິນ"
      size="lg"
      :show.sync="modalConfirmReceipt"
      :closeOnBackdrop="false"
    >
      <confirmReceipt
        :reportInv="reportInv"
        :reportInvList="reportInvList"
        @on_success="onSubmitPlay"
      />
      <template #footer>
        <div></div>
      </template>
    </CModal>
    <CModal
      title="ຂໍ້ມູນໃບຮຽກເກັບ ( Invoice )"
      size="lg"
      :show.sync="modalViewInvioce"
      :closeOnBackdrop="false"
    >
      <invoiceView :reportInv="reportInv" :reportInvList="reportInvList" />
      <template #footer>
        <div></div>
      </template>
    </CModal>
    <CModal
      title="ຂໍ້ມູນໃບຮັບເງິນ ( Receipt )"
      size="lg"
      :show.sync="modalViewReceipt"
      :closeOnBackdrop="false"
    >
      <receiptView :reportInv="reportInv" :reportInvList="reportInvList" />
      <template #footer>
        <div></div>
      </template>
    </CModal>
    <CModal
      title="ແກ້ໄຂຂໍ້ມູນໃບຮຽກເກັບ-ຮັບເງິນ"
      size="lg"
      :show.sync="modalUpdateReceipt"
      :closeOnBackdrop="false"
    >
      <inReFormUpdate :invoiceRecript="invoiceRecript" @on_success="onSubmitUpdate" />
      <template #footer>
        <div></div>
      </template>
    </CModal>
  </div>
</template>
<script>
import {
  getInvoiceReceiptAPI,
  createInvoiceReceiptAPI,
  confirmPlayInvoiceAPI,
  deleteInvoiceReceiptAPI,
  updateReceiptAPI
} from "@/api/invoiceReceipt";
import { getContractNoTExpAPI } from "@/api/contract";
import { getProposalStatusAPI } from "@/api/proposal";
import { getReportInvoiceByIdAPI } from "@/api/report-invoice";
import inReFormCreate from "./components/InReFormCreate";
import invoiceView from "./components/InvoiceView";
import confirmReceipt from "./components/ConfirmReceipt";
import receiptView from "./components/ReceipteView";
import inReFormUpdate from "./components/InReFormUpdate";
import { decrypt } from "@/libs/crypto";
export default {
  name: "InvoiceReceipt",
  components: {
    inReFormCreate,
    invoiceView,
    receiptView,
    confirmReceipt,
    inReFormUpdate,
  },
  data() {
    return {
      userGroup: decrypt(this.$store.getters.getUserGroup),
      loading: true,
      modalCreate: false,
      modalConfirmReceipt: false,
      modalViewInvioce: false,
      modalViewReceipt: false,
      modalUpdateReceipt: false,
      items: [],
      fields: [
        { key: "id", label: "ລໍາດັບ" },
        { key: "inv_no", label: "ເລກທີ່ບິນ" },
        { key: "inv_date", label: "ວັນທີ" },
        { key: "cus_full_name", label: "ຊື່ລຸກຄ້າ" },
        { key: "sum_total", label: "ຈໍານວນເງິນທັງຫມົດ" },
        { key: "inv_curency", label: "ສະກຸນເງິນ" },
        { key: "inv_project_name", label: "ໂຄງການ" },
        { key: "inv_status", label: "ສະຖານະ" },
        { key: "actions", label: "ຈັດການ" },
      ],
      contStList: [],
      ppsStList: [],
      reportInv: {},
      reportInvList: [],
      invoiceRecript: {},
    };
  },
  mounted() {
    this.initialGetData();
  },
  methods: {
    getBadge(status) {
      return status === "0" ? "danger" : status === "1" ? "success" : "success";
    },
    // Load data
    initialGetData() {
      setTimeout(() => {
        this.loading = false;
      }, 5000);
      getInvoiceReceiptAPI().then((response) => {
        this.items = response.data.data;
        this.loading = false;
      });
      getContractNoTExpAPI().then((response) => {
        this.contStList = response.data.data;
      });
      getProposalStatusAPI().then((response) => {
        this.ppsStList = response.data.data;
      });
    },

    onSubmitCreate(credentials) {
      createInvoiceReceiptAPI(credentials)
        .then((response) => {
          this.$awn.success(response.data.message);
          this.initialGetData();
        })
        .catch((error) => {
          this.$awn.alert(error.response.data.message);
        });
      this.modalCreate = false;
    },
    onSubmitCancle() {
      this.initialGetData();
      this.modalCreate = false;
    },
    onClickConfirmReceipt(item) {
      getReportInvoiceByIdAPI(item.inv_no).then((response) => {
        this.reportInv = response.data.data;
        this.reportInvList = response.data.data.list;
        this.modalConfirmReceipt = true;
      });
    },
    onSubmitPlay(credentials) {
      confirmPlayInvoiceAPI(credentials)
        .then((response) => {
          this.$awn.success(response.data.message);
          this.initialGetData();
        })
        .catch((error) => {
          this.$awn.alert(error.response.data.message);
        });
      this.modalConfirmReceipt = false;
    },
    onClickUpdateReceipt(item) {
      this.invoiceRecript = item;
      this.modalUpdateReceipt = true;
    },
    onSubmitUpdate(credentials) {
      updateReceiptAPI(credentials)
        .then((response) => {
          this.$awn.success(response.data.message);
          this.initialGetData();
        })
        .catch((error) => {
          this.$awn.alert(error.response.data.message);
        });
      this.modalUpdateReceipt = false;
    },
    onClickDeleteReceipt(item) {
      let onOk = () => {
        deleteInvoiceReceiptAPI(item.inv_no)
          .then((response) => {
            this.$awn.success(response.data.message);
            this.initialGetData();
          })
          .catch((error) => {
            this.$awn.alert(error.response.data.message);
          });
      };
      this.$awn.confirm("ເຈົ້າຕ້ອງການລົບຂໍ້ມູນນີ້ແທ້ ຫຼີ ບໍ່ ?", onOk);
    },
    onClickViewInvoice(item) {
      getReportInvoiceByIdAPI(item.inv_no).then((response) => {
        this.reportInv = response.data.data;
        this.reportInvList = response.data.data.list;
        this.modalViewInvioce = true;
      });
    },
    onClickViewReceipt(item) {
      getReportInvoiceByIdAPI(item.inv_no).then((response) => {
        this.reportInv = response.data.data;
        this.reportInvList = response.data.data.list;
        this.modalViewReceipt = true;
      });
    },
  },
};
</script>
