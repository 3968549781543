<template>
  <div>
    <CRow>
      <CCol sm="12">
        <div>
          <CRow>
            <CCol sm="12">
              <CRow>
                <CCol sm="1"></CCol>
                <CCol>
                  <CRow>
                    <CCol sm="4" class="text-right">ເລກທີ :</CCol>
                    <CCol sm="6">{{reportInv.inv_no}}</CCol>
                    <CCol sm="4" class="text-right">ວັນທິ :</CCol>
                    <CCol sm="6">{{reportInv.inv_date}}</CCol>
                    <CCol sm="4" class="text-right">ຊື່ລຸກຄ້າ :</CCol>
                    <CCol sm="6">{{reportInv.cus_full_name}}</CCol>
                    <CCol sm="4" class="text-right">ທີ່ຢູ່ :</CCol>
                    <CCol sm="6">{{reportInv.cus_address}}</CCol>
                    <CCol sm="4" class="text-right">ເບີໂທ :</CCol>
                    <CCol sm="6">{{reportInv.cus_tel}}</CCol>
                    <CCol sm="12">
                      <div class="my-4"></div>
                    </CCol>
                    <CCol sm="12">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th class="text-center">
                              <strong>ລດ</strong>
                            </th>
                            <th class="text-center w-50">
                              <strong>ລາຍການ</strong>
                            </th>
                            <th class="text-center">
                              <strong>ຫົວໜ່ວຍ</strong>
                            </th>
                            <th class="text-center">
                              <strong>ຈຳນວນ</strong>
                            </th>
                            <th class="text-center">
                              <strong>ລາຄາ</strong>
                            </th>
                            <th class="text-center w-25">
                              <strong>ຈຳນວນເງິນ</strong>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="row in reportInvList" :key="row.index">
                            <td class="text-center">{{row.index}}</td>
                            <td>
                              {{row.service_name}}
                              From : {{row.date_start}} To {{row.date_end}}
                            </td>
                            <td class="text-center">{{row.service_unit}}</td>
                            <td class="text-center">{{row.inv_amount | numFormat}}</td>
                            <td class="text-right">{{row.inv_price | numFormat(',000.00')}}</td>
                            <td class="text-right">{{row.inv_amount * row.inv_price | numFormat(',000.00')}}</td>
                          </tr>
                          <tr>
                            <td colspan="5" class="text-right">
                              <strong v-if="reportInv.inv_vat == 0">VAT :</strong>
                              <strong v-if="reportInv.inv_vat != 0">VAT {{reportInv.inv_vat}} %:</strong>
                            </td>
                            <td class="text-right">
                              <strong>{{reportInv.amount_vat | numFormat(',000.00')}}</strong>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </CCol>
                    <CCol sm="12">
                      <CCard border-color="danger">
                        <CCardBody class="text-right">
                          <strong
                            style="color: red;"
                          >ລວມເງິນທັງຫມົດ :{{reportInv.sum_total | numFormat(',000.00')}} {{reportInv.inv_curency}}</strong>
                        </CCardBody>
                      </CCard>
                    </CCol>
                    <CCol sm="12">
                      <hr />
                    </CCol>
                    <CCol sm="12">
                      <ValidationObserver v-slot="{ invalid }">
                        <CForm ref="form" :model="form" @submit.prevent="handleSubmit">
                          <CRow>
                            <CCol></CCol>
                            <CCol sm="5">
                              <validation-provider rules="required">
                                <div class="form-group">
                                  <v-select
                                    prop="select"
                                    v-model="form.select"
                                    :options="statusList"
                                    :reduce="code => code.code"
                                    label="message"
                                  />
                                </div>
                              </validation-provider>
                            </CCol>
                            <CCol sm="5">
                              <CButton
                                :disabled="invalid"
                                type="submit"
                                color="success"
                                style="width: 9rem"
                              >
                                <CIcon name="cil-check" />ໄດ້ຮັບເງິນແລ້ວ
                              </CButton>
                            </CCol>
                          </CRow>
                        </CForm>
                      </ValidationObserver>
                    </CCol>
                  </CRow>
                </CCol>
                <CCol sm="1"></CCol>
              </CRow>
            </CCol>
          </CRow>
        </div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
export default {
  name: "ConfirmReceipt",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    reportInv: {
      type: Object,
      required: true,
    },
    reportInvList: {
      type: Array,
      required: true,
    },
  },
  computed: {
    form() {
      return {
        select: "",
      };
    },
  },
  data() {
    return {
      statusList: [{ code: "1", message: "ໄດ້ຮັບເງິນເຕັມຈໍານວນແລ້ວ" }],
    };
  },
  methods: {
    handleSubmit() {
      this.$emit("on_success", {
        select: this.form.select,
        inv_no: this.reportInv.inv_no,
      });
      this.select = "";
    },
  },
};
</script>