<template>
  <div>
    <CRow>
      <CCol sm="12">
        <div>
          <CCard border-color="success">
            <CCardHeader>ກະລຸນາເລືອກຂໍ້ມູນ</CCardHeader>
            <CCardBody>
              <ValidationObserver v-slot="{ invalid }">
                <CForm ref="form" :model="form" @submit.prevent="handleCheckInfo">
                  <CRow>
                    <CCol sm="6">
                      <validation-provider rules="required">
                        <div class="form-group">
                          <label>ເລືອກຈາກ :</label>
                          <v-select
                            prop="select"
                            v-model="form.select"
                            :options="selectList"
                            :reduce="code => code.code"
                            label="message"
                          />
                        </div>
                      </validation-provider>
                    </CCol>
                    <CCol sm="6" v-if="form.select == 1">
                      <div class="form-group">
                        <label>ເລກທີ່ສັນຍາບໍລິການ :</label>
                        <v-select
                          prop="select_no"
                          v-model="form.select_no"
                          :options="cont"
                          :reduce="cont_no => cont_no.cont_no"
                          label="cont_no"
                        />
                      </div>
                    </CCol>
                    <CCol sm="6" v-if="form.select == 2">
                      <div class="form-group">
                        <label>ເລກທີ່ໃບສະເໜີລາຄາ :</label>
                        <v-select
                          prop="select_no"
                          v-model="form.select_no"
                          :options="pps"
                          :reduce="pps_no => pps_no.pps_no"
                          label="pps_no"
                        />
                      </div>
                    </CCol>
                    <CCol sm="12">
                      <CButton type="submit" :disabled="invalid" color="info" class="float-right">
                        <CIcon name="cil-check" />ຕົກລົງ
                      </CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </ValidationObserver>
            </CCardBody>
          </CCard>
        </div>
      </CCol>
      <CCol sm="12">
        <CRow>
          <CCol sm="12" v-if="contractInfo">
            <CRow>
              <CCol sm="12">
                <h4 class="text-center">ຂໍ້ມູນ</h4>
              </CCol>
              <CCol sm="4" class="text-right">ເລກທີ່ສັນຍາບໍລິການ :</CCol>
              <CCol sm="2">{{cusInfoCont.cont_no}}</CCol>
              <CCol sm="3" class="text-right">ວັນທີ :</CCol>
              <CCol sm="3">{{cusInfoCont.cont_date}}</CCol>
              <CCol sm="4" class="text-right">ຊື່ ແລະ ນາມສະກຸນ :</CCol>
              <CCol sm="8">{{cusInfoCont.cus_full_name}}</CCol>
              <CCol sm="4" class="text-right">ເບີໂທ :</CCol>
              <CCol sm="8">{{cusInfoCont.cus_tel}}</CCol>
              <CCol sm="4" class="text-right">ທີ່ຢູ່ :</CCol>
              <CCol sm="8">{{cusInfoCont.cus_address}}</CCol>
              <CCol sm="4" class="text-right">ໃຊ້ບໍລິການ :</CCol>
              <CCol sm="8">{{cusInfoCont.service_name}}</CCol>
              <CCol sm="4" class="text-right">ລາຄາ :</CCol>
              <CCol
                sm="8"
              >{{cusInfoCont.service_price | numFormat(',000.00')}} / {{cusInfoCont.service_unit}}</CCol>
              <CCol sm="4" class="text-right">ລາຍລະອຽດ :</CCol>
              <CCol sm="8">{{cusInfoCont.con_sv_remark}}</CCol>
            </CRow>
          </CCol>
          <CCol sm="12" v-if="proposalInfo">
            <CRow>
              <CCol sm="12">
                <h4 class="text-center">ຂໍ້ມູນ</h4>
              </CCol>
              <CCol sm="4" class="text-right">ເລກທີ່ສັນຍາບໍລິການ :</CCol>
              <CCol sm="2">{{cusInfoPps.pps_no}}</CCol>
              <CCol sm="3" class="text-right">ວັນທີ :</CCol>
              <CCol sm="3">{{cusInfoPps.pps_date}}</CCol>
              <CCol sm="4" class="text-right">ຊື່ ແລະ ນາມສະກຸນ :</CCol>
              <CCol sm="8">{{cusInfoPps.cus_full_name}}</CCol>
              <CCol sm="4" class="text-right">ເບີໂທ :</CCol>
              <CCol sm="8">{{cusInfoPps.cus_tel}}</CCol>
              <CCol sm="4" class="text-right">ທີ່ຢູ່ :</CCol>
              <CCol sm="8">{{cusInfoPps.cus_address}}</CCol>
              <CCol sm="4" class="text-right">ໂຄງການ :</CCol>
              <CCol sm="8">{{cusInfoPps.pps_project_name}}</CCol>
              <CCol sm="4" class="text-right">ສະກຸນເງິນ :</CCol>
              <CCol sm="8">{{cusInfoPps.pps_curency}}</CCol>
              <CCol sm="12">
                <hr />
              </CCol>
              <CCol sm="12">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">ລໍາດັບ</th>
                      <th scope="col">ລາຍການ</th>
                      <th scope="col">ຫົວໜ່ວຍ</th>
                      <th scope="col">ຈໍານວນ</th>
                      <th scope="col">ລາຄາ</th>
                      <th scope="col">ລວມ</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="row in cusInfoPps.serviceList" :key="row.index">
                      <th>{{row.index}}</th>
                      <th>{{row.service_name}}</th>
                      <th>{{row.service_unit}}</th>
                      <th>{{row.pps_amount | numFormat}}</th>
                      <th class="text-right">{{row.pps_price | numFormat(',000.00')}}</th>
                      <th
                        class="text-right"
                      >{{row.pps_amount * row.pps_price | numFormat(',000.00')}}</th>
                    </tr>
                    <tr>
                      <td colspan="5" class="text-right">
                        <strong>ລວມ ({{cusInfoPps.pps_curency}}):</strong>
                      </td>
                      <td class="text-right">
                        <strong>{{cusInfoPps.pps_price_total | numFormat(',000.00')}}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="5" class="text-right">
                        <strong>ອາກອນມູນຄ່າເພີ້ມ {{cusInfoPps.pps_vat}} % :</strong>
                      </td>
                      <td class="text-right">
                        <strong>{{cusInfoPps.amount_vat | numFormat(',000.00')}}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="5" class="text-right">
                        <strong>ລວມຍອດເງິນສຸດທິ :</strong>
                      </td>
                      <td class="text-right">
                        <strong>{{cusInfoPps.sum_total | numFormat(',000.00')}}</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </CCol>
            </CRow>
          </CCol>
          <CCol sm="12">
            <hr />
          </CCol>
          <CCol sm="12">
            <CForm ref="form" :model="form" @submit.prevent="handleSubmit">
              <CRow>
                <CCol sm="6">
                  <CInput
                    label="ຈໍານວນ :"
                    type="number"
                    prop="inv_amount"
                    v-model="form.inv_amount"
                    :disabled="proposalInfo"
                  />
                </CCol>
                <CCol sm="6">
                  <CInput
                    label="ອາກອນມູນຄ່າເພີ້ມ % :"
                    type="number"
                    prop="inv_vat"
                    v-model="form.inv_vat"
                    :disabled="proposalInfo"
                  />
                </CCol>
                <CCol sm="12">
                  <CInput
                    label="ຂໍ້ຄວາມໃນ Remark ບິນ :"
                    prop="inv_remark"
                    v-model="form.inv_remark"
                  />
                </CCol>
                <CCol sm="6">
                  <div class="form-group">
                    <label>ວັນທີເລີ່ມ :</label>
                    <date-picker :config="options" prop="date_start" v-model="form.date_start"></date-picker>
                  </div>
                </CCol>
                <CCol sm="6">
                  <div class="form-group">
                    <label>ວັນທີ່ໝົດ :</label>
                    <date-picker :config="options" prop="date_end" v-model="form.date_end"></date-picker>
                  </div>
                </CCol>
                <CCol sm="6" v-if="userGroup == 'administrator'">
                  <div class="form-group">
                    <label>ສະແດງລາຍເຊັນ :</label>
                    <v-select
                      prop="inv_signature"
                      v-model="form.inv_signature"
                      :options="signatureList"
                      :reduce="code => code.code"
                      label="message"
                    />
                  </div>
                </CCol>
                <CCol sm="12">
                  <div class="float-right">
                    <CButton type="submit" variant="outline" color="primary">
                      <CIcon name="cil-save" />ບັນທຶກ
                    </CButton>
                    <CButton @click="handleCancle" variant="outline" color="warning">
                      <CIcon name="cil-x" />ຍົກເລີກ
                    </CButton>
                  </div>
                </CCol>
              </CRow>
            </CForm>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
  </div>
</template>
 <script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { getInContByIdAPI, getInPpsByIdAPI } from "@/api/invoiceReceipt";
import { decrypt } from "@/libs/crypto";
export default {
  name: "InReFormCreate",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    cont: {
      type: Array,
      required: true,
    },
    pps: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      userGroup: decrypt(this.$store.getters.getUserGroup),
      contractInfo: false,
      proposalInfo: false,
      form: {
        select: "",
        select_no: "",
        inv_amount: 0,
        inv_vat: 0,
        inv_remark: "This pricess is Not Include VAT 10%",
        date_start: "",
        date_end: "",
        inv_signature: 0
      },
      selectList: [
        { code: 1, message: "ສັນຍາບໍລິການ" },
        { code: 2, message: "ໃບສະເໜີລາຄາ" },
      ],
      signatureList: [
        { code: 0, message: "ບໍ່ສະແດງ" },
        { code: 1, message: "ສະແດງ" },
      ],
      cusInfoCont: {},
      cusInfoPps: {},
      serviceList: [],
      inv_project: "",
      inv_project_name: "",
      inv_price_total: "",
      inv_vat: "",
      inv_curency: "",
      inv_remark: "",
      cont_inv_no: "",
      cus_id: "",
      options: {
        format: "YYYY-MM-DD",
        useCurrent: false,
      },
    };
  },
  methods: {
    handleCheckInfo() {
      let select_no = this.form.select_no;
      if (this.form.select == 1) {
        getInContByIdAPI(select_no)
          .then((response) => {
            this.cusInfoCont = response.data.data;
            // setting show info
            this.contractInfo = true;
            this.proposalInfo = false;
            // Set paramiter
            this.inv_project = "0";
            this.inv_project_name = "";
            this.inv_curency = "KIP";
            this.cont_inv_no = this.cusInfoCont.cont_no;
            this.cus_id = this.cusInfoCont.cus_id;
          })
          .catch((error) => {
            this.$awn.alert("Error, Please try again");
          });
      } else if (this.form.select == 2) {
        getInPpsByIdAPI(select_no)
          .then((response) => {
            this.cusInfoPps = response.data.data;
            // setting show info
            this.contractInfo = false;
            this.proposalInfo = true;
            // Set paramiter
            this.inv_project = this.cusInfoPps.pps_project;
            this.inv_project_name = this.cusInfoPps.pps_project_name;
            this.inv_price_total = this.cusInfoPps.pps_price_total;
            this.inv_curency = this.cusInfoPps.pps_curency;
            this.inv_vat = this.cusInfoPps.pps_vat;
            this.cont_inv_no = this.cusInfoPps.pps_no;
            this.cus_id = this.cusInfoPps.cus_id;
            this.serviceList = this.cusInfoPps.serviceList;
          })
          .catch((error) => {
            this.$awn.alert("Error, Please try again");
          });
      }
    },
    handleCancle() {
      this.$emit("on_cancle", {});
      //clear
      this.form.select = "";
      this.form.select_no = "";
      this.form.inv_vat = 0;
      this.form.inv_amount = 0;
      this.form.inv_remark = "This pricess is Not Include VAT 10%";
      this.form.date_start = "";
      this.form.date_end = "";
      this.form.inv_signature = 0;
      this.cusInfoCont = {};
      this.cusInfoPps = {};
    },
    handleSubmit() {
      if (this.contractInfo == true) {
        this.inv_price_total = (this.form.inv_amount * this.cusInfoCont.service_price);
        this.inv_vat = this.form.inv_vat;
        this.serviceList = [
          {
            index: "1",
            pps_price: this.cusInfoCont.service_price,
            pps_amount: this.form.inv_amount,
            pps_remark: "",
            service_id: this.cusInfoCont.service_id,
          },
        ];
      }
      console.log(this.inv_price_total);
      this.$emit("on_success", {
        cont_inv_no: this.cont_inv_no,
        inv_project: this.inv_project,
        inv_project_name: this.inv_project_name,
        inv_price_total: this.inv_price_total,
        inv_curency: this.inv_curency,
        inv_vat: this.inv_vat,
        inv_remark: this.form.inv_remark,
        inv_by: decrypt(this.$store.getters.getFullName),
        cus_id: this.cus_id,
        date_start: this.form.date_start,
        date_end: this.form.date_end,
        inv_signature: this.form.inv_signature,
        serviceList: this.serviceList,
      });
      //clear
      this.form.select = "";
      this.form.select_no = "";
      this.form.inv_amount = 0;
      this.form.inv_vat = 0;
      this.form.inv_remark = "This pricess is Not Include VAT 10%";
      this.form.date_start = "";
      this.form.date_end = "";
      this.form.inv_signature = 0;
      this.cusInfo = {};
      this.serviceList = [];
      this.inv_project = "";
      this.inv_project_name = "";
      this.inv_price_total = "";
      this.inv_curency = "";
      this.inv_by = "";
      this.cus_id = "";
    },
  },
};
</script>