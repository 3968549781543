<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CRow>
          <CCol sm="1"></CCol>
          <CCol sm="3">ເລກທີບິນ :</CCol>
          <CCol sm="2">{{invoiceRecript.inv_no}}</CCol>
          <CCol sm="3" class="text-right">ວັນທີ :</CCol>
          <CCol sm="3">{{invoiceRecript.inv_date}}</CCol>
          <CCol sm="1"></CCol>
          <CCol sm="3">ຊື່ ແລະ ນາມສະກຸນ :</CCol>
          <CCol sm="8">{{invoiceRecript.cus_full_name}}</CCol>
          <CCol sm="1"></CCol>
          <CCol sm="3">ເບີໂທ :</CCol>
          <CCol sm="8">{{invoiceRecript.cus_tel}}</CCol>
          <CCol sm="1"></CCol>
          <CCol sm="3" >ທີ່ຢູ່ :</CCol>
          <CCol sm="8">{{invoiceRecript.cus_address}}</CCol>
          <CCol sm="1"></CCol>
          <CCol sm="3">ໂຄງການ :</CCol>
          <CCol sm="8">{{invoiceRecript.inv_project_name}}</CCol>
          <CCol sm="1"></CCol>
          <CCol sm="3">ຈໍານວນເງິນທັງໝົດ :</CCol>
          <CCol sm="8">{{invoiceRecript.sum_total | numFormat(',000.00')}} {{invoiceRecript.inv_curency}}</CCol>
        </CRow>
      </CCol>
      <CCol sm="12">
        <CForm ref="form" :model="form" @submit.prevent="handleSubmit">
          <CRow>
            <CCol sm="1"></CCol>
            <CCol sm="4">
              <CInput
                label="ອາກອນມູນຄ່າເພີ້ມ % :"
                type="number"
                prop="inv_vat"
                v-model="form.inv_vat"
              />
            </CCol>
            <CCol sm="6" v-if="userGroup == 'administrator'">
                  <div class="form-group">
                    <label>ສະແດງລາຍເຊັນ :</label>
                    <v-select
                      prop="inv_signature"
                      v-model="form.inv_signature"
                      :options="signatureList"
                      :reduce="code => code.code"
                      label="message"
                    />
                  </div>
                </CCol>
            <CCol sm="1"></CCol>
            <CCol sm="1"></CCol>
            <CCol sm="10">
              <CInput label="ຂໍ້ຄວາມໃນ Remark ບິນ :" prop="inv_remark" v-model="form.inv_remark" />
            </CCol>
            <CCol sm="11">
              <div class="float-right">
                <CButton type="submit" variant="outline" color="warning">
                  <CIcon name="cil-save" />ບັນທຶກ
                </CButton>
              </div>
            </CCol>
          </CRow>
        </CForm>
      </CCol>
    </CRow>
  </div>
</template>
 <script>
import { decrypt } from "@/libs/crypto";
export default {
  name: "InReFormUpdate",
  props: {
    invoiceRecript: {
      type: Object,
      required: true,
    },
  },
  computed: {
    form() {
      return {
        inv_vat: this.invoiceRecript.inv_vat,
        inv_remark: this.invoiceRecript.inv_remark,
        inv_signature: this.invoiceRecript.inv_signature
      };
    },
  },
  data(){
    return {
      userGroup: decrypt(this.$store.getters.getUserGroup),
      signatureList: [
        { code: 0, message: "ບໍ່ສະແດງ" },
        { code: 1, message: "ສະແດງ" },
      ],
    }
  },
  methods: {
    handleSubmit() {
      this.$emit("on_success", {
        id: this.invoiceRecript.id,
        inv_vat: this.form.inv_vat,
        inv_remark: this.form.inv_remark,
        inv_signature: this.form.inv_signature,
        inv_by: decrypt(this.$store.getters.getFullName),
      });
    },
  },
};
</script>